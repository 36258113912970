import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Alta conversão.",
          "Geração de Leads.",
          "Foco e Clareza.",
          "Credibilidade e Profissionalismo.",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
