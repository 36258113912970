import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              POR QUE TER UMA <span className="purple"> LANDING PAGE </span> ?
            </h1>
            <p className="home-about-body">
            Daqui a alguns anos, haverá dois tipos de empresas:
              
              <br />Aquelas que conduzem negócios pela Internet e as que estão fora do mercado.
              <br /><br />O mundo digital é a
              <i>
                <b className="purple"> chave </b>
              </i>
              
              para o crescimento exponencial da sua empresa, contudo, é &nbsp;
              <i>
                <b className="purple">fundamental </b> ter uma página profissional para aproveitar plenamente essas oportunidades.{" "}
              </i>
              <br />
              <br />
              Desenvolvo as
              <b className="purple"> melhores</b> páginas com <b className="purple">preços acessíveis</b>.
              <br />
              <i>
                Entre em contato e solicite seu
                <b className="purple">
                  {" "}
                  orçamento
                </b>.
              </i>
              
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>ME ENCONTRE EM</h1>
            <p>
            Fique à vontade para se <span className="purple">conectar </span>comigo
            </p>
            <ul className="home-about-social-links">
              
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/wquadros/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/wquadros98"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
