import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import Demo01 from "../../Assets/Projects/Demo01.png";
import cvjlle from "../../Assets/Projects/cvjlle.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Meus <strong className="purple">projetos </strong> recentes
        </h1>
        <p style={{ color: "white" }}>
         Aqui estão alguns projetos em que trabalhei recentemente.<br/>
         *Página em manutenção*
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "200px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Demo01}
              isBlog={false}
              title="Modelo 01"
              demoLink="https://modelo01.wquadros.xyz/"
            />
          </Col>
         

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cvjlle}
              isBlog={false}
              title="Carro Vendido Joinville"
              demoLink="https://cvjoinville.com.br/"
            />
          </Col>
         </Row>

         
        </Container>

    </Container>
  );
}

export default Projects;
